<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true">
            
        </kendo-grid>

        <file-viewer ref="fileViewer"/>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import retailServices from '../Script/RetailServices';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import FileViewer from '../../../../shared/FileViewer.vue';
import { kendo_grid } from '../../../../infrastructure/constant/variable';


export default {
    name: 'RetailGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'file-viewer': FileViewer,
    },
    props: ['editClick', 'deleteClick', 'statusClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: kendo_grid.default_grid_pagesize,
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                const paging = {
                                    Page : {Limit: e.pageSize,Page: e.page},
                                    Filter : globalfunc.filterQueryBuilder(e.filter),
                                    OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                                }
                                const variables = {
                                    paging : paging,
                                    projecttype : 2
                                }
                                return { query: retailServices.readRetailQuery(),
                                         variables : variables}
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if(response.data.GetTransactionProject.project == null){
                                return [];
                            }else{
                                return response.data.GetTransactionProject.project;
                            }
                        },
                        total: function (response) {
                            if(response.data.GetTransactionProject.project == null){
                                return 0;
                            }else{
                                return response.data.GetTransactionProject.total;
                            }
                        },
                        model: {
                            fields: {
                                project_date: {type: "date"},
                            }
                        }
                    },
                    sort: { field: "project_date", dir: "desc" }
                }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "project_code", title: "Kode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "project_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "project_detail.limit", title: "Limit", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template: "#= kendo.toString(Math.round(project_detail.limit), 'n2') #"  },
                { field: "city", title: "Kota", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "project_date", title: "Tgl. Mulai", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(project_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" }, 
                { field: "project_duration", title: "Durasi (Bulan)", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "sales_name", title: "Salesman", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "attachment", title: "Attachment", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template:this.columnFile },
                { field: "price_category_name", title: "Jenis Harga", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", title: "Keterangan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.project_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem.project_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem.project_id, true);
        })
        
        //Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            statusClick(dataItem);
        })

        //File Form
        GridElement.on("click", "#FileButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            vueComponent.$refs.fileViewer.viewClick(dataItem.attachment);
        })
    },
    methods: {
        columnFile(e){
            return this.$globalfunc.gridFileViewButton(e.attachment);
        },
        columnButton(){
           return this.$globalfunc.gridActionWithStatusChangesButton("Retail")
        },
        changeStatus(status){
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                projecttype : 2,
                                status : status
                            }
                            return { query: retailServices.readRetailQuery(),
                                        variables : variables}
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionProject.project == null){
                            return [];
                        }else{
                            return response.data.GetTransactionProject.project;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionProject.project == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionProject.total;
                        }
                    },
                    model: {
                        fields: {
                            project_date: {type: "date"},
                        }
                    }
                },
                sort: { field: "project_date", dir: "desc" }
            });
        }
    }
}
</script>