import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class RetailService {
    readRetailQuery(){
        var query = `query($paging:ServerPaging, $projecttype:Int, $status:[String]){
            GetTransactionProject(Paging:$paging, ProjectType:$projecttype, Status:$status){
                project{
                    project_id
                    status
                    project_code
                    project_name
                    project_date
                    city
                    notes
                    project_duration
                    sales_name
                    price_category_name
                    pre_project_code
                    project_detail
                    attachment
                }
                total
            }
        }`;
        return query;
    }
    
    readItemQuery(){
        var query = `query($priceCategoryId:String!,$storageId:String!,$typeDetailId:String){
                        GetItemForcastV2(   ActiveFlag:"A", 
                                            PriceCategoryId:$priceCategoryId, 
                                            StorageId:$storageId, 
                                            TypeDetailId:$typeDetailId, 
                                            Type:"Project"){
                            item_id
                            product_code
                            item_name
                            active_flag
                            type_detail_id
                            type_detail_name
                            group_type_id
                            item_group_name
                            base_uom
                            price_category_id
                            storage_id
                            ItemForcastDetail{
                                item_id
                                product_code
                                item_name
                                active_flag
                                type_detail_id
                                type_detail_name
                                group_type_id
                                item_group_name
                                base_uom
                                uom_conv
                                conv_amount
                                storage_id
                                price_category_id
                                price_retail
                                price_project
                                stock
                            }
                        }
                    }`;
        return query;
    }

    countBaseDiscountForecast(forecastData, typeDetailData){
        var hashMap = [];
        typeDetailData.forEach(el=>{
            hashMap[el.type_detail_id]= el
        });
        var discountData = hashMap[forecastData.type_detail_id];
        
        var discountAmount1 = Math.round(forecastData.base_price_retail * (discountData.discount1 / 100));
        var discountAmount2 = Math.round((forecastData.base_price_retail - discountAmount1) * (discountData.discount2 / 100));
        var discountAmount3 = Math.round((forecastData.base_price_retail - discountAmount1 - discountAmount2) * (discountData.discount3 / 100));
        var discountAmount4 = Math.round((forecastData.base_price_retail - discountAmount1 - discountAmount2 - discountAmount3) * (discountData.discount4 / 100));
        var discountAmount5 = Math.round((forecastData.base_price_retail - discountAmount1 - discountAmount2 - discountAmount3 - discountAmount4) * (discountData.discount5 / 100));
        var total_discount = discountAmount1 + discountAmount2 + discountAmount3 + discountAmount4 + discountAmount5;

        return forecastData.base_price_retail - total_discount;
    }

    countBaseDiscountCashForecast(discountCash, basePriceretailDiscount){
        return basePriceretailDiscount - Math.round(basePriceretailDiscount * (discountCash / 100));
    }

    calculateForecastData(forecastData, typeDetailData, discountCash){
        var basePriceretailDiscount = this.countBaseDiscountForecast(forecastData, typeDetailData);
        var basePriceretailDiscountCash = this.countBaseDiscountCashForecast(parseFloat(discountCash), basePriceretailDiscount);

        var str = { 
                po: forecastData.po, 
                item_id: forecastData.item_id,
                product_code: forecastData.product_code, 
                item_name: forecastData.item_name,
                type_detail_id: forecastData.type_detail_id,
                base_uom: forecastData.base_uom,
                uom_conv: forecastData.uom_conv,
                conv_amount: forecastData.conv_amount,
                quantity: forecastData.quantity,
                //price
                base_price_retail: forecastData.base_price_retail,
                price_retail: forecastData.price_retail,
                total_price_retail: forecastData.quantity * forecastData.price_retail,
                //price discount
                base_price_retail_discount: basePriceretailDiscount,
                price_retail_discount: basePriceretailDiscount * forecastData.conv_amount,
                total_price_retail_discount: basePriceretailDiscount * forecastData.conv_amount * forecastData.quantity,
                //price discount cash
                base_price_retail_discount_cash: basePriceretailDiscountCash,
                price_retail_discount_cash: basePriceretailDiscountCash * forecastData.conv_amount,
                total_price_retail_discount_cash: basePriceretailDiscountCash * forecastData.conv_amount * forecastData.quantity,
            };
            
        return str;
    }

    countTotal(data){
        var result = 0;
        if(data.length > 0){
            for (let i = 0; i < data.length; i++) {
                result = result + data[i].total_price_retail_discount_cash 
            }
        }
        return result
    }

    async getTypeDetailQuery(){
        var query = gql`query{
            GetTypeDetail(Flags:["All","Retail"]) {
                type_detail_id
                type_detail_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetTypeDetail;

        var typeDetailData = [];
        for (let i = 0; i < data.length; i++) {
            var str = { type_detail_id : data[i].type_detail_id,
                        type_detail_name : data[i].type_detail_name,
                        discount1 : 0,
                        discount2 : 0,
                        discount3 : 0,
                        discount4 : 0,
                        discount5 : 0,
                        is_shown : false}
            typeDetailData.push(str);
        }
        return typeDetailData
    }
}

export default new RetailService();